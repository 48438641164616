// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
export const configJSON = require("./config");
import { getStorageData} from "../../../framework/src/Utilities";


export interface RequiredQuoteDataSaved {
    id: number;
    quote_id: string;
    date: string | null;
    vendor: string;
    project: string;
    status: string;
    total_price: number;
    tag: string;
    supplier_address: string;
    supplier_salesperson: string;
    supplier_contact: string;
    site_name: string;
    site_address: string;
    site_contactperson: string;
    site_contact: string;
    subtotal: number;
    s_and_h_charges: number;
    other_charges: number;
    tax: number;
    tax_rate_amount: number;
    discount: number;
    expiration_date: string;
    quote_descriptions: string | null;
    created_at: string;
    updated_at: string;
    report_id: number;
    gross_subtotal: number;
    pdf_page_count: number;
  }

export interface AddNameDialogProps {
    title: string;
    type: string;
    handleClose: any;
    handleOpen: any;
    handleInputChange: any;
    inputName: any;
    inputEmail:any;
    addVendor: any;
    addProject: any;
    classes: any;
    addNameErrMsg: any;
    addEmailErrMsg:any;
}

export interface Props {
    modalOpen: boolean,
    classes: any,
    navigation: any,
    handleSaveData: () => {},
    history: any;
    tabIndexValue: any;
    location: any;
    projectValue:any;
}
export interface TabPanelProps {
    // Customizable Area Start
    children?: React.ReactNode;
    index: number;
    value: number;
    // Customizable Area End
}

interface ProjectNamesInterface {
    project: string;
    id: number;
}

interface VendorNamesInterface {
    name: string;
    id: number;
}

export interface ReportDetailsProps {
    classes: any,
    reportNameErrMsg: string,
    checkedBox: boolean,
    handleCheckboxChange: (event: any) => void,
    reportName: string,
    handleVendorChange: (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => void,
    vendorValue: string,
    vendorItems: VendorNamesInterface[],
    handleProjectChange: (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => void,
    projectValue: string,
    projectItems: ProjectNamesInterface[],
    handleReportValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleOpenVendorDialog: (event: React.MouseEvent<HTMLLIElement>) => void,
    handleOpenProjectDialog: (event: React.MouseEvent<HTMLLIElement>) => void,
    vendorErrMsg: string,
    projectErrMsg: string,
    reportsName: string,
    projectStatus?:any,
}



export interface GetRootProps {
    onClick: () => void;
    onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
    onDragEnter: (event: React.DragEvent<HTMLDivElement>) => void;
    onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
    onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
    role: string;
    tabIndex: number;
    'aria-haspopup': boolean;
    'aria-controls': string;
}

export interface GetInputProps {
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    type: string;
    tabIndex: number;
    multiple: boolean;
    accept: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}


export interface RateSheetDropboxprops {
    classes: any,
    acceptedFile: any,
    onDrop: (acceptedFiles: File[]) => void,
    uploadAcceptedFile: any[]
    pdfUrl: string,
    handleDelete: () => void,
    tabValue: number,
}

export interface RenderDialogProps {
    openDialog: boolean;
    handleCloseDialog: () => void;
    apiError: boolean;
    message: string;
    isLoading: boolean;
}

export interface InvoiceurlBoxProps {
    invoiceUrl: string, 
    handleChange: (event: string, newValue: number) => void,
    enableContinueButton:boolean
}

interface CreateTableDataItem {
    order_unit: string;
    category_description: any[],
    project: number,
    id: any,
    description: string,
    ord_quantity: string,
    unit: string,
    price: string,
    amount: string,
    report_invoice_id: number,
    created_at: string,
    updated_at: string,
    tax: string,
    invoice_category_name: string,
    invoice_category_subtotal: null
    taxSwitch: boolean
  }
  
  interface CreateTableDescription {
    id: number,
    invoice_category_name: string,
    content: string,
    data: CreateTableDataItem[],
    pagination: {
      id: number,
      page: number,
      rowsPerPage: number
    },
    warningTableBox: boolean,
    subtotalValue: number,
    selected: number[],
    categoryErrorMessage: string,
    addNewSegmentState: boolean
  }

interface S {
    // Customizable Area Start
    value: number | any | undefined;
    reportTab: any;
    accept: any;
    projects: any;
    progressVal: number;
    compareModal: boolean;
    isQuoteUploadenableorDisable: boolean;
    breadCrumbCreateReport: boolean;
    pdf_id: string;
    quote_id: string;
    apiError: boolean;
    uploadAcceptedFile: [];
    uploadAcceptedInvoiceFile: any;
    invoiceCompareResult: string;
    quoteUploadValidateMessage: string;
    invoiceCompareError: boolean;
    acceptedFileType: any;
    noCompareFileId: null;
    documentModal: boolean;
    timeRemaining: any;
    invoiceUploadLoader: boolean;
    openDialog: boolean;
    isLoading: boolean;
    vendorValue: any;
    pdfListData:any;
    projectValue: any;
    projectName: string;
    vendorName: string;
    vendorItems: any;
    projectItems: any;
    type: any;
    inputName: any;
    inputEmail:any;
    reportName: any;
    addNameErrMsg: any;
    addEmailErrMsg: any;
    pdfUrl: any;
    detailInvoice:any;
    isNoQuoteModalOpen: any;
    isCancelModalOpen: any;
    reportNameErrMsg: any;
    quoteDetails: any;
    quoteDescription: any;
    invoiceDetails: any,
    invoiceDescription: any,
    isUploadLaterModalOpen: any;
    isQuoteUploaded: any;
    quoteUrl: any;
    invoiceUrl: any;
    vendorErrMsg: any;
    projectErrMsg: any;
    invoiceValue: any;
    invoiceTabs: any;
    multipleInvoiceUrl: any;
    quotePdfCount: any;
    hitAPis:any;
    invoicePdfCount: any;
    quoteCategoryDescription: any
    segmentName1: any;
    segmentTotal1: any;
    segmentName2: any;
    segmentTotal2: any;
    quoteAllDescription: []
    quoteUrlId: any;
    updated_table_data: any;
    saveBtnUpdatedResponse: any
    reportId: string,
    checkedBox: boolean,
    uploadAcceptedRateSheetFile: string[]
    newUpdatedDataArr: any;
    isInvoiceUploadenableorDisable:boolean;
    invoiceUploadValidateMessage:string;
    editQuoteDataSaved:boolean;
    editQuoteRequiredData:RequiredQuoteDataSaved [] | object;
    enableContinueButton:boolean;
    reportsName: string;
    invoiceURLs: string[];
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CreateReportController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start

    getVendorsApiCallId: string = "";
    getQuoteId: string = "";
    getProjectsApiCallId: string = "";
    addVendorsId: string = "";
    addProjectsId: string = "";
    uploadReportQuotesFileId: string = "";
    uploadReportInvoicesFileId: string = "";
    skipUploadQuotesFileId: string = "";
    skipUploadInvoiceFileId: string = "";
    deleteQuoteApiId: string = "";
    deleteInvoiceApiId: string = "";
    uploadReportRateSheetFileId: string = "";
    resultQuoteDropApiId: string = "";
    invoiceDetailIdValue:string = "";

    constructor(props: Props) {
        super(props);
        this.state = {
            projects: [],
            value: this.props.location?.state?.value || 0,
            pdf_id: '',
            quoteUploadValidateMessage: "Fill up all the field to upload quote",
            quote_id: '',
            apiError: false,
            accept: [],
            breadCrumbCreateReport: false,
            projectValue: '',
            projectName: "",
            vendorName: "",
            pdfListData:[],
            openDialog: false,
            hitAPis:0,
            isQuoteUploadenableorDisable: true,
            acceptedFileType: { "application/pdf": [".pdf"] },
            quoteAllDescription: [],
            reportTab: [
                {
                    id: 1,
                    type: "Upload Quote",
                    visited: true
                },
                {
                    id: 2,
                    type: "Review Quote Details",
                    visited: false
                },
                {
                    id: 3,
                    type: "Upload Invoice",
                    visited: false
                },
                {
                    id: 4,
                    type: "Review Invoice Details",
                    visited: false
                },
                {
                    id: 5,
                    type: "Results",
                    visited: false
                },
            ],
            progressVal: 0,
            documentModal: false,
            uploadAcceptedFile: [],
            uploadAcceptedInvoiceFile: [],
            uploadAcceptedRateSheetFile: [],
            invoiceCompareResult: "",
            invoiceCompareError: false,
            noCompareFileId: null,
            timeRemaining: 20,
            compareModal: false,
            invoiceUploadLoader: false,
            isLoading: false,
            vendorValue: '',
            type: '',
            vendorItems: [],
            projectItems: [],
            inputName: '',
            inputEmail:'',
            reportName: '',
            addNameErrMsg: '',
            addEmailErrMsg: '',
            pdfUrl: '',
            detailInvoice:'',
            isNoQuoteModalOpen: false,
            isCancelModalOpen: false,
            reportNameErrMsg: '',
            quoteDetails: {},
            quoteDescription: [],
            quoteCategoryDescription: [],
            invoiceDetails: [] || this.props.location?.state?.newUpdatedDataArr,
            invoiceDescription: [],
            isUploadLaterModalOpen: false,
            isQuoteUploaded: false,
            quoteUrl: "",
            invoiceUrl: "",
            vendorErrMsg: "",
            projectErrMsg: "",
            invoiceValue: 0,
            invoiceTabs: ["invoice", "invoicetwo"],
            multipleInvoiceUrl: [],
            quotePdfCount: 1,
            invoicePdfCount: 1,
            segmentName1: "",
            segmentTotal1: 0,
            segmentName2: "",
            segmentTotal2: 0,
            quoteUrlId: "",
            updated_table_data: [],
            reportId: "",
            saveBtnUpdatedResponse: [],
            checkedBox: false,
            newUpdatedDataArr: [],
            isInvoiceUploadenableorDisable:true,
            invoiceUploadValidateMessage:"Fill up all the field to upload invoice",
            editQuoteDataSaved:false,
            editQuoteRequiredData:{},
            enableContinueButton:false,
            reportsName: "",
            invoiceURLs:[]
        };
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }

    async componentDidMount() {
            this.getProjects()
            this.getVendors()    
            this.handlePropsLocation()
            
            const { projectValue, reportValue, value, vendorValue, report_ID,reportsName,continueBtnClicked,quotedetail,quotestate} = this.props?.location?.state;
            report_ID && this.getQuotes(report_ID)
        
       this.initializeReportData({ projectValue, reportValue, value, vendorValue, reportsName, continueBtnClicked });
        

        this.updateStateForInvoiceUpload(projectValue, reportValue, vendorValue);

        if (this.props.location.state.navigateId) {
            this.setState({
                value: this.props.history.location.state.navigateId,
            })
            this.setState(this.props.location.state.creteReportData)
            this.setState({
                quoteDescription:this.getviewData() ,
                quoteCategoryDescription: this.props.location.state.tablesData,
                quoteDetails:this.props.location.state.cancelData ? this.props.location.state.creteReportData.quoteDetails :  this.props.location.state.quoteDetails
            })

            this.setState({
                newUpdatedDataArr: this.props.location.state.newUpdatedDataArr,
                updated_table_data: this.props.location.state.updated_table_data,
                invoiceDetails: this.props.location.state.updated_table_data
            })

        }

        if(!this.props.location.state.updated_table_data){
            this.setState({
                updated_table_data: this.props.location.state.creteReportData.updated_table_data,
                invoiceDetails: this.props.location.state.creteReportData.invoiceDetails
            })
        }

        

        if (this.props.location.state.saveBtnUpdatedResponse) {
            this.setState({ 
                saveBtnUpdatedResponse: this.props.location.state.saveBtnUpdatedResponse,
            })
        }

        if(!this.props.location.state.tablesData){
            this.setState({
                quoteCategoryDescription: this.props.location.state.creteReportData.quoteCategoryDescription
            })
        }

        if(this.props.location.state.editQuoteDataSaved){
            this.setState({
                editQuoteDataSaved:this.props.location.state.editQuoteDataSaved
            })
        }

        if(quotedetail){
            this.setState({
                quoteDetails:quotedetail
            })
        }

        if(quotestate){
           this.setState({
            quoteDescription:quotestate
           })
        }

       
    }


    async componentWillUnmount() {
        // Customizable Area Start
        this.setState({ value: 0 })
        // Customizable Area End
    }


    handlePropsLocation =()=>{

        if(this.props.location.state.value === 2){
            const reportID = this.props.location.state.reportIDData ? this.props.location.state.reportIDData : this.props.location.state.report_ID;
            const projectID = this.props.location.state.projectValue ;

            const header = {
                token: this.getToken()
            };
            const requestReportQuotesMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
    
            this.invoiceDetailIdValue = requestReportQuotesMessage.messageId;
            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.reportGetDetailAPIs}report_id=${reportID}&project_id=${projectID}`
            );
            
            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
    
        
            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.fileGetApiMethod
            );
    
            runEngine.sendMessage(requestReportQuotesMessage.id, requestReportQuotesMessage);
    
            return true;
        }
    
    }

    initializeReportData({ projectValue, reportValue, value, vendorValue, reportsName, continueBtnClicked }:any) {
        if (projectValue && reportValue && value) {
            const reportsNameValue = continueBtnClicked ? reportValue : reportsName;
            this.setState({
                value: Number(value),
                reportName: reportValue,
                projectValue,
                vendorValue,
                reportsName: reportsNameValue,
            });
        }
    }

    updateStateForInvoiceUpload(projectValue:any, reportValue:any, vendorValue:any) {
        if (projectValue && reportValue && vendorValue) {
            this.setState({
                isInvoiceUploadenableorDisable: false,
                invoiceUploadValidateMessage: "",
            });
        }
    }

    getviewData = () => {
        return this.props.location.state.cancelData? this.props.location.state.creteReportData.quoteDescription : this.props.location.state.tablesData
    };

    onQuoteReportDrop = (acceptedFiles: any) => {
        this.setState({ uploadAcceptedFile: acceptedFiles, isLoading: true });
        if (this.state.uploadAcceptedFile.length > 0) {
            const header = {
                token: this.getToken()
            };

            const reportQuotesFormData: any = new FormData();

            acceptedFiles.forEach((file: any) => {
                reportQuotesFormData.append('files', file);
            });

            const requestReportQuotesMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.uploadReportQuotesFileId = requestReportQuotesMessage.messageId;
            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.fileUploadApiEndPoint}?vendor_id=${this.state.vendorValue}&project_id=${this.state.projectValue}&report_name=${this.state.reportsName}`
            );

            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                reportQuotesFormData
            );

            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.fileUploadApiMethod
            );

            runEngine.sendMessage(requestReportQuotesMessage.id, requestReportQuotesMessage);

            return true;
        }
    };

    onQuoteResultDrop = (acceptedFiles: any) => {
        this.setState({ uploadAcceptedFile: acceptedFiles, isLoading: true });
        if (this.state.uploadAcceptedFile.length > 0) {
            const header = {
                token: this.getToken()
            };

            const reportQuotesFormData: any = new FormData();

            acceptedFiles.forEach((file: any) => {
                reportQuotesFormData.append('file', file);
            });

            const requestQuotesMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.resultQuoteDropApiId = requestQuotesMessage.messageId;
            requestQuotesMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.quoteResultApiEndPoint}?report_id=${this.state.reportId}&rate_sheet=yes`
            );

            requestQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                reportQuotesFormData
            );

            requestQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.fileUploadApiMethod
            );

            runEngine.sendMessage(requestQuotesMessage.id, requestQuotesMessage);

            return true;
        }
    };


    onInvoiceReportDrop = (acceptedFiles: any) => {
       
        const maxFile = 8;
        const { uploadAcceptedInvoiceFile } = this.state;
        const checkfile = uploadAcceptedInvoiceFile.length + acceptedFiles.length;
       
        if (checkfile > maxFile) {
            const firstValues = acceptedFiles.slice(0,maxFile - uploadAcceptedInvoiceFile.length);
            this.validationInvoiceFile(firstValues)
        } else {
            this.validationInvoiceFile(acceptedFiles)
        }

    };


    convertResponseToFileArray = async (responseArray:any) => {
        const filePromises = responseArray.map(async (item:any) => {
            const response = await fetch(item.url);
            if (!response.ok) {
                throw new Error(`Failed to fetch ${item.url}: ${response.statusText}`);
            }
    
            const blob = await response.blob();
            return new File([blob], item.name || 'file', { type: item.type });
        });
    
        return Promise.all(filePromises);
    };


    validationInvoiceFile = (firstValues:File[]) =>{
        this.setState(prevState => ({ uploadAcceptedInvoiceFile: [...prevState.uploadAcceptedInvoiceFile, ...firstValues], isLoading: true,enableContinueButton:false }));
        if (this.state.uploadAcceptedInvoiceFile.length > 0) {
            const header = {
                token: this.getToken()
            };
            const reportQuotesFormData: any = new FormData();

            firstValues.forEach((file: File, index: number) => { reportQuotesFormData.append(`files${index + 1}`, file);});
            const requestReportQuotesMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
          
            this.uploadReportInvoicesFileId = requestReportQuotesMessage.messageId;


            requestReportQuotesMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.invoiceUploadApiEndPoint}?vendor_id=${this.state.vendorValue}&project_id=${this.state.projectValue}&report_name=${this.state.reportsName}`);

            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                reportQuotesFormData
            );

            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.fileUploadApiMethod
            );

            runEngine.sendMessage(requestReportQuotesMessage.id, requestReportQuotesMessage);

            return true;
        }

    };

    onRateSheetportDrop = (acceptedFiles: any) => {
        let rateValue = "";
        if (this.state.checkedBox === true) {
            rateValue = "yes"
        }

        this.setState({ uploadAcceptedRateSheetFile: acceptedFiles, isLoading: true });
        if (this.state.uploadAcceptedRateSheetFile.length > 0) {
            const header = {
                token: this.getToken()
            };

            const reportQuotesFormData = new FormData();

            acceptedFiles.forEach((file: File) => {
                reportQuotesFormData.append('files', file);
            });

            const requestReportQuotesMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.uploadReportRateSheetFileId = requestReportQuotesMessage.messageId;
            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.fileUploadApiEndPoint}?vendor_id=${this.state.vendorValue}&project_id=${this.state.projectValue}&report_name=${this.state.reportsName}&rate_sheet=${rateValue}`
            );

            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                reportQuotesFormData
            );

            requestReportQuotesMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.fileUploadApiMethod
            );

            runEngine.sendMessage(requestReportQuotesMessage.id, requestReportQuotesMessage);

            return true;
        }
    };

    handleProjectValue = (event: React.ChangeEvent<{ value?: string | unknown }>) => {
        const selectedValue = event.target.value as string;
        this.setState({ projectValue: selectedValue });
    }

    getToken = () => {
        return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ invoiceValue: newValue, invoiceUrl: this.state.multipleInvoiceUrl[newValue] })
    }

    handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { target: { value,name } } = event;
       if(name === "inputName") {
            this.setState({ inputName: value, addNameErrMsg: '' })
        } else  {
            this.setState({ inputEmail: value,addEmailErrMsg:''})
        }
    }

    handleReportValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { target: { value } } = event;
        this.setState({ reportsName: value, reportNameErrMsg: '' }, () => {
            this.handleQuoteUploadEnableorDisabled(this.state.reportName, this.state.vendorValue, this.state.projectValue);
        });
    }
    
    handleQuoteUploadEnableorDisabled = (reportName: string, vendorValue: string, projectValue: string) => {
        if (vendorValue || projectValue || reportName) {
            this.setState({ isQuoteUploadenableorDisable: false, quoteUploadValidateMessage: "" });
        } else {
            this.setState({ isQuoteUploadenableorDisable: true, quoteUploadValidateMessage: "Fill up all the field to upload quote" });
        }
    }

    handleBack = () => {
        if (this.state.isQuoteUploaded === true) {
            this.setState({ value: 1 })
        } else {
            const { report_ID } = this.props?.location?.state;
            report_ID && this.getQuotes(report_ID)
            this.setState({ value: 0 })
        }
    }

    addVendors = () => {
        const header = {
            token: this.getToken()
        };

        const formData: any = new FormData();

        formData.append('name', this.state.inputName);
        formData.append('email',this.state.inputEmail);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addVendorsId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getVendorsEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addInputNameMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    addProjects = () => {
        const header = {
            token: this.getToken()
        };

        const formData: any = new FormData();

        formData.append('project', this.state.inputName);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addProjectsId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getProjectsEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addInputNameMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    getVendors = () => {
        if(this.state.vendorValue !== ""){
        this.setState({ isInvoiceUploadenableorDisable: false, invoiceUploadValidateMessage: "" })
        }
       
        const header = {
            token: this.getToken()
        };

        const vendorRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getVendorsApiCallId = vendorRequestMessage.messageId

        vendorRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getVendorsEndPoint
        );

        vendorRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        vendorRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUploadApiMethod
        );
        runEngine.sendMessage(vendorRequestMessage.id, vendorRequestMessage);

        return true;
    };

    getQuotes = (id: number) => {
        this.setState({ isQuoteUploadenableorDisable: false, quoteUploadValidateMessage: "", breadCrumbCreateReport: true })

        const header = {
            token: this.getToken()
        };

        const quatesRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getQuoteId = quatesRequestMessage.messageId

        quatesRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteReportEndPoint}/report_details_dashboard?id=${id}`
        );

        quatesRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        quatesRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUploadApiMethod
        );

        runEngine.sendMessage(quatesRequestMessage.id, quatesRequestMessage);

        return true;

    }

    getProjects = () => {
        const header = {
            token: this.getToken()
        };

        const projectRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProjectsApiCallId = projectRequestMessage.messageId

        projectRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getProjectsEndPoint
        );

        projectRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        projectRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUploadApiMethod
        );
        runEngine.sendMessage(projectRequestMessage.id, projectRequestMessage);

        return true;
    };

      
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.skipUploadQuotesFileId) {
                this.handleSkipQuoteResponse(responseJson)
            }
            if (apiRequestCallId === this.skipUploadInvoiceFileId) {
                this.handleSkipInvoiceResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteQuoteApiId) {
                this.setState({ uploadAcceptedFile: [], quoteUrl: '', quoteDetails: {}, quoteDescription: [] })
            }

            if(apiRequestCallId === this.invoiceDetailIdValue && this.props.location.state.value === 2){
                this.handleDetailApiStep2(responseJson)
            }

            this.handleGetVendorResponse(apiRequestCallId, responseJson)
            this.handleGetProjectResponse(apiRequestCallId, responseJson)
            this.handleGetQuoteResponse(apiRequestCallId, responseJson)
            this.handleAddProjectResponse(apiRequestCallId, responseJson)
            this.handleAddVendorResponse(apiRequestCallId, responseJson)
            this.handleUploadQuoteResponse(apiRequestCallId, responseJson)
            this.handleUploadInvoiceResponse(apiRequestCallId, responseJson)
            this.handleRateSheetResponse(apiRequestCallId, responseJson)
            this.handleQuoteResultResponse(apiRequestCallId, responseJson)
        }
    }

    handleDetailApiStep2 =async(responseJson:any)=>{
        const uploadedInvoiceName = responseJson?.report_details?.invoice_pdf_urls;
        const reportIDData = this.props?.location.state?.reportIDData;
        const Data2 =  responseJson?.report_details?.invoice_pdf_urls;
        const Data = responseJson?.report_details?.invoice_pdf_urls?.map((val: any) => val?.url);
        const quoteUrlData = responseJson?.report_details?.quote_pdf_urls?.map((val:any)=>val?.url)
        
       this.setState({
           vendorValue:responseJson?.report_details?.vendor_id,
           projectValue:responseJson.report_details.project_id,
           invoiceUrl:uploadedInvoiceName ? uploadedInvoiceName :'',
           detailInvoice:Data ? Data : '',
           enableContinueButton:true,
           multipleInvoiceUrl:Data ? Data : '',
           isInvoiceUploadenableorDisable:false,
           quoteUrl:quoteUrlData ? quoteUrlData :'',
           reportsName:reportIDData,
           hitAPis:this.state.hitAPis+1
       })    

       const handleDataValue:any[] =  await this.convertResponseToFileArray(Data2);
       if (this.state.hitAPis === 1){
        this.validationInvoiceFile(handleDataValue)
       }
    }

    handleRateSheetResponse = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.uploadReportRateSheetFileId && responseJson.url) {
            this.setState({ quoteAllDescription: responseJson, quoteUrl: responseJson.url, quotePdfCount: responseJson.pdf_page_count, quoteDetails: responseJson.quotes.data.attributes, quoteDescription: responseJson.quote_descriptions, isQuoteUploaded: true, isLoading: false })
            this.setState({ quoteUrl: responseJson.url, quotePdfCount: responseJson.pdf_page_count, quoteDetails: responseJson.quotes.data.attributes, quoteDescription: responseJson.quote_descriptions, quoteUrlId: responseJson.url_id.id, isQuoteUploaded: true, isLoading: false })
        } else {
            let report_name = "";
            let project_id = "";
            let vendor_id = "";
            if (Array.isArray(responseJson)) {
                responseJson.forEach((obj: any) => {
                    if (obj.hasOwnProperty("report_name")) {
                        report_name = obj.report_name
                    }else if(obj.hasOwnProperty("project_id")){
                        project_id = obj.project_id
                    }else if(obj.hasOwnProperty("vendor_id")){
                        vendor_id = obj.vendor_id
                    }
                })
            }
            this.setState({ reportNameErrMsg: report_name ? report_name : "", vendorErrMsg: vendor_id ? vendor_id : "", projectErrMsg: project_id ? project_id : "", isLoading: false })
        }
    }


    handleAddVendorResponse = (apiRequestCallId: any, responseJson: any) => {

        if (apiRequestCallId === this.addVendorsId) {
            if (responseJson.errors) {
                if(responseJson.errors.includes("Name") || responseJson.errors.includes("name")){
                this.setState({ addNameErrMsg: responseJson.errors})
                }else if (responseJson.errors.includes("Email") || responseJson.errors.includes("email")){
                this.setState({ addEmailErrMsg: responseJson?.errors})
                }
                else {
                    this.setState({ addNameErrMsg: responseJson.errors}) 
                }
            } else {
                this.setState({ openDialog: false, inputName: '', inputEmail:'',vendorValue: responseJson.data.attributes.id, vendorName: responseJson.data.attributes.name });
                this.getVendors()
                this.handleQuoteUploadEnableorDisabled(this.state.reportName, this.state.vendorValue, this.state.projectValue)
            }
        }
    }

    handleAddProjectResponse = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.addProjectsId) {
            if (responseJson.errors) {
                if(responseJson.errors[0].project){
                this.setState({ addNameErrMsg: responseJson.errors[0].project })
                }else{
                this.setState({ addNameErrMsg: responseJson.errors })
                }
            } else {
                this.setState({ openDialog: false, inputName: '', projectValue: responseJson.data.attributes.id, projectName: responseJson.data.attributes.project });
                this.getProjects()
                this.handleQuoteUploadEnableorDisabled(this.state.reportName, this.state.vendorValue, this.state.projectValue)
            }
        }
    }

    handleGetProjectResponse = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getProjectsApiCallId) {
            const data: any[] = responseJson.data.map((tableData: any) => ({
                ...tableData.attributes,
            }));
            this.setState({ projectItems: data })
        }
    }

    handleGetQuoteResponse = (apiRequestCallId: any, responseJson: any) => {
        if(responseJson?.data?.attributes?.quote){
            this.setState({
                editQuoteRequiredData:responseJson?.data?.attributes?.quote
            })
        }

        if (apiRequestCallId === this.getQuoteId && responseJson.url) {
            this.setState({ quoteAllDescription: responseJson, quoteUrl: responseJson.url, quotePdfCount: responseJson.pdf_page_count, quoteDetails: responseJson.quotes.data.attributes[0], quoteDescription: responseJson.quote_descriptions, isLoading: false })
            this.setState({ quoteUrl: responseJson.url, quotePdfCount: responseJson.pdf_page_count, quoteDetails: responseJson.quotes.data.attributes[0], quoteDescription: responseJson.quote_descriptions, quoteUrlId: responseJson.url_id.id, isLoading: false })
        } else {
            const projectError = responseJson['project_id']
            const vendorError = responseJson['vendor_id']
            const reportError = responseJson['report_name'];
            this.setState({ reportNameErrMsg: reportError ? reportError.report_name : "", vendorErrMsg: vendorError ? vendorError.vendor_id : "", projectErrMsg: projectError ? projectError.project_id : "", isLoading: false })
        }
    }

    handleGetVendorResponse = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getVendorsApiCallId) {
            const data: any[] = responseJson.data.map((tableData: any) => ({
                ...tableData.attributes,
            }));
            this.setState({ vendorItems: data })
        }
    }

    handleUploadQuoteResponse = (apiRequestCallId: any, responseJson: any) => {

        if (apiRequestCallId === this.uploadReportQuotesFileId && responseJson.url) {
            this.setState({segmentTotal1:responseJson.quotes.data.attributes.subtotal, quoteAllDescription: responseJson, quoteUrl: responseJson.url, quotePdfCount: responseJson.pdf_page_count, quoteDetails: responseJson.quotes.data.attributes, quoteDescription: responseJson.quote_descriptions, isQuoteUploaded: true, isLoading: false })
            this.setState({ quoteUrl: responseJson.url, quotePdfCount: responseJson.pdf_page_count, quoteDetails: responseJson.quotes.data.attributes, quoteDescription: responseJson.quote_descriptions, quoteUrlId: responseJson.url_id.id, isQuoteUploaded: true, isLoading: false,editQuoteDataSaved:false })
        } else {
            const projectError = responseJson['project_id']
            const vendorError = responseJson['vendor_id']
            const reportError = responseJson['report_name'];
            this.setState({ reportNameErrMsg: reportError ? reportError.report_name : "", vendorErrMsg: vendorError ? vendorError.vendor_id : "", projectErrMsg: projectError ? projectError.project_id : "", isLoading: false })
        }
    }


    handleQuoteResultResponse = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.resultQuoteDropApiId && responseJson.url) {
            this.setState({ quoteAllDescription: responseJson, quoteUrl: responseJson.url, quotePdfCount: responseJson.pdf_page_count, quoteDetails: responseJson.quotes.data.attributes, quoteDescription: responseJson.quote_descriptions, isQuoteUploaded: true, isLoading: false, quoteUrlId: responseJson.url_id.id, })
        }
    }

    handleSkipQuoteResponse = (responseJson: any) => {
        if (responseJson?.data) {
            this.setState({ value: 2, reportId: responseJson.data.id })
        } else {
            const projectError = responseJson[0].project_id;
            const vendorError = responseJson[0].vendor_id;
            const reportError = responseJson[0].report_name;
            this.setState({ reportNameErrMsg: reportError, vendorErrMsg: vendorError, projectErrMsg: projectError, isLoading: false })
        }
    }

    handleSkipInvoiceResponse = (responseJson: any) => {
        if (responseJson?.data) {
            this.setState({ value: 4 })
        } else {
            const projectError = responseJson['project_id']
            const vendorError = responseJson['vendor_id']
            const reportError = responseJson[0].report_name;

            this.setState({ reportNameErrMsg: reportError, vendorErrMsg: vendorError ? vendorError.vendor_id : "", projectErrMsg: projectError ? projectError.project_id : "", isLoading: false })
        }
    }

    handleUploadInvoiceResponse = (apiRequestCallId: any, responseJson: any) => {
        if (!Array.isArray(responseJson)) {
            return;
        }


        if (apiRequestCallId === this.uploadReportInvoicesFileId && responseJson[0]?.url) {
            const urls = responseJson.map((file: any) => file.url)
            this.setState((prevState)=>({
                invoiceURLs:[...prevState.invoiceURLs,...urls]
            }))
            this.setState({ multipleInvoiceUrl: [...this.state.invoiceURLs], invoiceUrl: urls, invoiceDetails: [...this.state.invoiceDetails,...responseJson],enableContinueButton:true })
        } else {
            responseJson.forEach((obj: any) => {
                if (obj.hasOwnProperty("vendor_id")) {
                    this.setState({ vendorErrMsg: obj.vendor_id })
                }
                if (obj.hasOwnProperty("project_id")) {
                    this.setState({ projectErrMsg: obj.project_id })
                }
                if (obj.hasOwnProperty("report_name")) {
                    this.setState({ reportNameErrMsg: obj.report_name })
                }
            });
            this.setState({ isLoading: false })
        }
    }

    handleOpenVendorDialog = (event: any) => {
        event?.stopPropagation();
        this.setState({ openDialog: true, type: 'vendor' })
    }

    handleOpenProjectDialog = (event: any) => {
        event?.stopPropagation();
        this.setState({ openDialog: true, type: 'project' })
    }

    handleOpenNoQuoteModal = (event: any) => {
        if (this.state.reportsName != "" && this.state.projectName != "" && this.state.vendorName != "") {
            event?.stopPropagation();
            this.setState({ isNoQuoteModalOpen: true });
        }
    }

    handleOpenUploadLaterModal = (event: any) => {
        event?.stopPropagation();
        this.setState({ isUploadLaterModalOpen: true })
    }

    handleCloseUploadLaterModal = () => {
        this.setState({ isUploadLaterModalOpen: false })
    }

    handleUploadQuote = () => {
        this.setState({ value: 0, isUploadLaterModalOpen: false })
    }

    handleOpenCancelModal = (event: any) => {
        event?.stopPropagation();
        this.setState({ isCancelModalOpen: true })
    }

    handleCancel = () => {
        this.props.history.goBack()
        this.handleDelete()
    }

    handleClose = () => {
        this.setState({ openDialog: false, inputName: '', addNameErrMsg: '',inputEmail:'',addEmailErrMsg:''});
    };

    handleContinueForInvoiceUpload = () => {

        this.setState({ isNoQuoteModalOpen: false })
        const header = {
            token: this.getToken()
        };

        const requestReportQuotesMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.skipUploadQuotesFileId = requestReportQuotesMessage.messageId;
        requestReportQuotesMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.fileUploadApiEndPoint}?vendor_id=${this.state.vendorValue}&project_id=${this.state.projectValue}&report_name=${this.state.reportsName}`
        );

        requestReportQuotesMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestReportQuotesMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fileUploadApiMethod
        );

        runEngine.sendMessage(requestReportQuotesMessage.id, requestReportQuotesMessage);

        return true;
    }

    handleNoQuoteModalClose = () => {
        this.setState({ isNoQuoteModalOpen: false });
    }

    handleCancelModalClose = () => {
        this.setState({ isCancelModalOpen: false });
    }

    handleDelete = async () => {
        const header = {
            token: this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteQuoteApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteQuoteEndpoint}?report_id=${this.state.quoteDetails.report_id}&url_id=${this.state.quoteUrlId}&report_quote_id=${this.state.quoteDetails.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    invoiceDeleteApi = async (index: any) => {
        if (this.state.uploadAcceptedInvoiceFile.length > 1) {
            const deletedInvoice = this.state.uploadAcceptedInvoiceFile.filter((item: any, itemIndex: any) => itemIndex !== index)
            const deletedInvoiceItem = this.state.invoiceDetails.filter((item: any, itemIndex: any) => itemIndex !== index)
            const deletedUrl = this.state.multipleInvoiceUrl.filter((item: any, itemIndex: any) => itemIndex !== index)
            this.setState({ uploadAcceptedInvoiceFile: deletedInvoice, multipleInvoiceUrl: deletedUrl, invoiceUrl: deletedUrl[0], invoiceValue: 0, invoiceDetails: deletedInvoiceItem, invoiceDescription: [] })
        } else {
            this.setState({ uploadAcceptedInvoiceFile: [], invoiceUrl: '', multipleInvoiceUrl: [], invoiceDetails: [], invoiceDescription: [] })
        }
    }

    handleDeleteInvoice = async (index: any) => {
        this.invoiceDeleteApi(index)
        const invoiceData = this.state.invoiceDetails[index]
        const header = {
            token: this.getToken()
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteInvoiceApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteInvoiceEndpoint}?report_id=${invoiceData.invoice.data.attributes.report_id}&url_id=${invoiceData.invoice.data.attributes.url_id}&report_invoice_id=${invoiceData.invoice.data.attributes.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleVendorChange = (event: any) => {
        if (event.target.value !== undefined) {
            this.setState({ vendorValue: event.target.value, vendorErrMsg: "" }, () => {
                const selectVendor = this.state.vendorItems.find((vendor: VendorNamesInterface) => {
                    return this.state.vendorValue === vendor.id;
                });
                this.setState({ vendorName: selectVendor?.name });
                this.handleQuoteUploadEnableorDisabled(this.state.reportName, event.target.value, this.state.projectValue);
                this.handleInvoiceUploadEnableorDisabled(event.target.value)
            })
        }
    }

    handleProjectChange = (event: any) => {
        if (event.target.value !== undefined) {
            this.setState({ projectValue: event.target.value, projectErrMsg: "" }, () => {
                const selectProject = this.state.projectItems.find((project: ProjectNamesInterface) => {
                    return this.state.projectValue === project.id;
                });
                this.setState({ projectName: selectProject?.project });
                this.handleQuoteUploadEnableorDisabled(this.state.reportName, this.state.vendorValue, event.target.value);
            })
        }
    }

    handleChange = (event: string, newValue: number) => {
        if (newValue == 4) {


            if (this.state.reportTab[2].visited) {
                const updatedTabs = this.updateTabs(newValue)
                this.setState({ reportTab: updatedTabs, value: newValue })
            }
            else {
                const header = {
                    token: this.getToken()
                };

                const requestReportQuotesMessage = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                );

                this.skipUploadInvoiceFileId = requestReportQuotesMessage.messageId;
                requestReportQuotesMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    `${configJSON.invoiceUploadApiEndPoint}?vendor_id=${this.state.vendorValue}&project_id=${this.state.projectValue}&report_name=${this.state.reportsName}`
                );

                requestReportQuotesMessage.addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );

                requestReportQuotesMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    configJSON.fileUploadApiMethod
                );

                runEngine.sendMessage(requestReportQuotesMessage.id, requestReportQuotesMessage);

                return true;
            }
        }
        else {


            const updatedTabs = this.updateTabs(newValue)

            this.setState({ reportTab: updatedTabs, value: newValue })
            if (newValue == 0) {
                this.setState({ value: newValue, projectValue: "", vendorValue: '', reportTab: updatedTabs })
            }
            if (this.state.value == 1) {
                this.setState({ value: newValue, reportTab: updatedTabs })
            }
        }
    }

    NavigateToEditQuote = () => {
        this.props.history.push('/report/editquotescanresult',
            {
                segmentName1: this.state.segmentName1,
                segmentTotal1: this.state.segmentTotal1,
                segmentName2: this.state.segmentName2,
                segmentTotal2: this.state.segmentTotal2,
                quoteCategoryDescription: this.state.quoteCategoryDescription,
                quotestate: this.state.quoteDescription,
                quotedetail: this.state.quoteDetails,
                value: this.state.value,
                goBackButtonNavigation: this.goBackButtonNavigation("", 1),
                createReportData: this.state,
                checkedBox: this.state.checkedBox

            })
    }

    NavigateToEditInvoice = () => {
        this.props.history.push('/report/editinvoicescanresult', {
            invoiceDetails: this.props.location?.state?.updated_data || this.state.invoiceDetails,
            createReportData: this.state,
            invoiceTab: this.state.uploadAcceptedInvoiceFile,
            value: this.state.value,
            goBackButtonNavigation: this.goBackButtonNavigation("", 3),
            updated_table_data: this.state.updated_table_data,
            checkedBox: this.state.checkedBox,
            quotedetail: this.state.quoteDetails,
            quoteCategoryDescription: this.state.quoteCategoryDescription,
            quotestate: this.state.quoteDescription,
            newUpdatedInvoiceDetails:this.state.invoiceDetails,
        })
    }

    goBackButtonNavigation = (currentTab: any, tabIndex: any) => {
        if (currentTab === 4) {
            const updatedTabs = this.state.reportTab.map((tab: any, index: number) => ({
                ...tab,
                visited: index === currentTab ? true : tab.visited,
            }));

            this.setState({ reportTab: updatedTabs, value: tabIndex })
        }

        this.setState({ value: tabIndex })
    }

    updateTabs = (newValue: any) => {

        const updatedTabs = this.state.reportTab.map((tab: any, index: number) => ({
            ...tab,
            visited: index === newValue - 1 ? true : tab.visited,
        }));
        return updatedTabs
    }


    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            checkedBox: event.target.checked
        })
    };
  
    changeNewUpdatedDataArr = (array: any) => {
        return array.map((item: {tablesData: CreateTableDescription[]}) => {
            const newArr = item.tablesData.map((table) => ({
                data: table.data,
                subtotalValue: table.subtotalValue,
                invoice_category_name: table.invoice_category_name
            }));
            return newArr;
        });
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<any> {
        if (prevState.newUpdatedDataArr !== this.state.newUpdatedDataArr) {
            const outputArr = this.changeNewUpdatedDataArr(this.state.newUpdatedDataArr);
            const updatedInvoiceDetails = [...this.state.invoiceDetails];
    
            outputArr.forEach((categoryArr: any[], index: number) => {
                const categorizedArrays: { [key: string]: any[] } = {};
                const uncategorizedItems: any[] = [];
    
                categoryArr.forEach((table) => {
                    const { data, subtotalValue, invoice_category_name } = table;
                    data.forEach((dt: { invoice_category_name: string }) => {
                        const category = dt?.invoice_category_name || invoice_category_name;
                        const item = {
                            ...dt,
                            subtotalValue,
                        };
    
                        if (category) {
                            categorizedArrays[category] = categorizedArrays[category] || [];
                            categorizedArrays[category].push(item);
                        } else {
                            uncategorizedItems.push(item);
                        }
                    });
                });
    
                const combinedDescriptions = [
                    ...Object.keys(categorizedArrays).map(category => ({ [category]: categorizedArrays[category] })),
                    ...uncategorizedItems
                ];
    
                updatedInvoiceDetails[index].invoice_description = combinedDescriptions;
            });
    
            this.setState({ invoiceDetails: updatedInvoiceDetails });
        }

        if(prevState.invoiceURLs.length !== this.state.invoiceURLs.length){
            this.setState({
                invoiceValue:this.state.invoiceURLs.length-1
            })
        }

       
    }

    handleInvoiceUploadEnableorDisabled = ( vendorValue: string) => {
        if (vendorValue !== "") {
            this.setState({ isInvoiceUploadenableorDisable: false, invoiceUploadValidateMessage: "" });
        } else {
            this.setState({ isInvoiceUploadenableorDisable: true, invoiceUploadValidateMessage: "Fill up all the field to upload invoice" });
        }
    }

    getQuoteName = () => {
        const { uploadAcceptedFile, uploadAcceptedRateSheetFile } = this.state;
        let result;

        if (uploadAcceptedFile.length > 0) {
          result = uploadAcceptedFile;
        } else if (uploadAcceptedRateSheetFile.length > 0) {
          result = uploadAcceptedRateSheetFile;
        } else {
          result = [{ name: 'Quote' }];
        }
        
        return result;
    }
    // Customizable Area End
}
