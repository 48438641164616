// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");


interface columnInvoiceData {
  id: string;
  label: string;
}

interface invoiceDetailInterface{
  invoice_subtotal: string,
  invoice_shipped_date: string,
  date: string,
  quote_id: string,
  dateVal: string,
  expiryDateVal: string,
  invoice_site_name: string,
  invoice_salesperson_name: string,
  invoice_supplier_contact: string,
  invoice_supplier_address: string,
  project: string,
  invoice_site_address: string,
  invoice_site_person: string,
  invoice_site_contact: string,
  invoice_terms: string
}
export interface Props {
  // Customizable Area Start
  classes: any;
  navigation: any;
  id: string;
  handleSaveData: (data: any) => void;
  location: any;
  history: any;
  // Customizable Area End
}

interface InvoiceItem {
  id: number;
  report_invoice_id: number;
  description: string;
  ord_quantity: number;
  order_unit: string;
  price: string;
  amount: string;
  tax: string | null;
  equipment: string | null;
  minimum: string | null;
  day: string | null;
  week: string | null;
  month: string | null;
  invoice_category_name: string | null;
  created_at: string;
  updated_at: string;
}

interface InvoiceDescriptionGroup {
  no_category_created: InvoiceItem[];
}

interface InvoiceDescriptionGroupArray {
  invoice_description_group: InvoiceDescriptionGroup[];
}


interface S {
  // Customizable Area Start
  invoiceComparisonData: any[],
  invoiceDetailData: invoiceDetailInterface[],
  quotePage: number,
  quoteRowsPerPage: number,
  invoiceColumn: columnInvoiceData[],
  lastReportname:string,
  reportof:{id:number,projectName:string},
  invoiceViewTabIndex:number,
  invoiceSingleDetailData: invoiceDetailInterface[],
  invoiceDescGroup:InvoiceDescriptionGroupArray[],
  invoiceTaxColumn:any,
  invoicePageRequired:number,
  paginationArrScanResult:any,
  paginationKeyScan:string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class InvoiceScanResultController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.state = {
      // Customizable Area Start
      invoiceSingleDetailData:[],
      invoiceViewTabIndex:0,
      reportof:{id:0,projectName:""},
      lastReportname:'',
      invoiceComparisonData: [],
      invoiceDetailData: [],
      quotePage: 1,
      quoteRowsPerPage: 10,
      invoiceColumn: [
        {
          id: 'Sr.no', label: 'Sr. No',
        },
        {
          id: 'description', label: 'Description',
        },
        {
          id: 'OrderQty.', label: 'Order Qty.',
        },
        {
          id: 'unit', label: 'Unit',
        },
        {
          id: 'unitPrice', label: 'Unit Price',
        },
        {
          id: 'Ext. Price', label: 'Ext. Price'
        }
      ],
      invoiceDescGroup:[],
      invoiceTaxColumn: [
        {
          id: 'Sr.no', label: 'Sr. No',
        },
        {
          id: 'description', label: 'Description',
        },
        {
          id: 'OrderQty.', label: 'Order Qty.',
        },
        {
          id: 'unit', label: 'Unit',
        },
        {
          id: 'unitPrice', label: 'Unit Price',
        },
        {
          id: 'tax', label: 'Tax (%)',
        },
        {
          id: 'Ext. Price', label: 'Ext. Price'
        }
      ],
      invoicePageRequired:1,
      paginationArrScanResult:[],
      paginationKeyScan:"invoice_1_pagination_1"
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const itemGetData= await getStorageData("itemsData");
    const parseItemsdata = JSON.parse(itemGetData);

    console.log("parseItemsdata::",parseItemsdata)

    const reportofGet= await getStorageData("reportof");
    const reportofName = JSON.parse(reportofGet);
    const reportName = parseItemsdata?.reports_details?.data.attributes.report_name

    const invoiceDescGroup = parseItemsdata?.invoice_description_group

    const invoiceItemTable = [parseItemsdata].map((tablevalue) => tablevalue.invoice_description);
    const invoiceItemGet = invoiceItemTable[0][0].data
    const invoiceData = [parseItemsdata]?.find((invoiceitem) => invoiceitem?.invoices_details).invoices_details;

    this.setState({
      invoiceComparisonData:invoiceItemGet,
      invoiceSingleDetailData:invoiceData,
      invoiceDetailData: [invoiceData[0]],
      lastReportname:reportName,
      reportof:reportofName,
      invoiceDescGroup,

    })

    setTimeout(()=>{
      this.createPaginationArrScanResult(this.state.invoiceDescGroup)
    },1000)
  }

  handleChangeinvoicePage = (event: unknown, newPage: number,paginationKeyScan:any) => {
    const updatedPaginationArr = this.state.paginationArrScanResult.map((paginationObj:any) => {
      if (paginationObj.hasOwnProperty(paginationKeyScan)) {
        return {
          ...paginationObj,
          [paginationKeyScan]: newPage
        };
      }
      return paginationObj;
    });


    this.setState({ invoicePageRequired: newPage,paginationArrScanResult: updatedPaginationArr,paginationKeyScan})
  };

  goBackInvoicehandler = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),'ViewReportDetails');
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(message);
  }

  // Customizable Area Start
  getNavigationReport = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),'ProjectS');
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(message);
  }

  getNavigationViewreport = async() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),'Viewreport');
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(message);
  }

  handleViewinvoceTabChange = async(event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ invoiceViewTabIndex: newValue })
    const singleInvoicedetail = this.state.invoiceSingleDetailData && this.state.invoiceSingleDetailData[newValue]
    const itemGetData= await getStorageData("itemsData");
    const parseItemsdata = JSON.parse(itemGetData);
    const invoiceGetItemTable = [parseItemsdata].map((tablevalue) => tablevalue.invoice_description);
    const invoiceGetItemGet = invoiceGetItemTable[0][newValue].data
    this.setState({invoiceDetailData:[singleInvoicedetail],invoiceComparisonData:invoiceGetItemGet})
  }

  segmentsArrayScanResult(arr: any[]) {
    return arr.map((item1: { [x: string]: any[]; }) => {
      const segments = Object.keys(item1);
      return segments.map((segment1) => {
        const hasTax1 = item1[segment1].some((entry: { tax: any; }) => entry.tax > 0);
        const segmentTotalAmount1 = item1[segment1].reduce((total, entry) => {
          return total + parseFloat(entry.amount || 0); 
        }, 0);
        return {
          segment: segment1,
          hasTax: hasTax1,
          segmentTotalAmount:segmentTotalAmount1,
        };
      });
    });
  }

  handleChangePageInvoiceScanWrapper = (paginationKey:any) => {
    return (event: unknown, newPage: number) => {
      this.handleChangeinvoicePage(event, newPage, paginationKey);
    };
  };

  createPaginationArrScanResult = (invoiceDescriptionGroup:any)=>{
    const paginationArrScanResult = invoiceDescriptionGroup.map((group_scan:any,groupIdx:number) => {
      const paginationObj:any = {};
      let paginationCounter = 1;
  
      for (const key in group_scan) {
        if (group_scan[key] && Array.isArray(group_scan[key])) {
          paginationObj[`invoice_${groupIdx+1}_pagination_${paginationCounter}`] = 1;
          paginationCounter++;
        }
      }
  
      return paginationObj;
    });

    this.setState({
      paginationArrScanResult
    })
  }

  // Customizable Area End
}
