// Customizable Area Start
import React from "react";
import {
    Box,
    Grid, Button,Breadcrumbs, Link,
    Typography, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination
} from "@material-ui/core";
import { editSvg } from "../src/assets";
import Pagination from '@material-ui/lab/Pagination';
import { createTheme, withStyles } from "@material-ui/core/styles";
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Header from "../../landingpage/src/Header.web";
import QuoteScanResultController, { Props, configJSON, DataItemQuote } from "./QuoteScanResultController.web";
import moment from "moment";
import { styled } from "@material-ui/styles";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

export const ViewReportDetailsBreadCrumb = (props: any) => {
    const { classes, project, report,getNavigationViewreport,goBack } = props
    return (<>
        <Box className={classes.breadcrumbStyle}>
            <Breadcrumbs separator={">"} aria-label="breadcrumb">
                <Link color="inherit" className={classes.breadcrumbLinkStyle} style={webStyle.textUnderStyle} href="/Projects">
                    {configJSON.projectsTitle}
                </Link>

                <UploadReportContainer className={classes.breadcrumbText} style={webStyle.textUnderStyle} onClick={getNavigationViewreport}>{configJSON.reportOf}'{project}'</UploadReportContainer>

                <UploadReportContainer className={classes.breadcrumbText} style={webStyle.checkboxChecked} onClick={goBack}>{report}</UploadReportContainer>
                <Typography className={classes.breadcrumbText} style={webStyle.scanTextStyle}>{"View Quote Scan Results"}</Typography>
            </Breadcrumbs>
        </Box>
    </>)

}




// Customizable Area End
class QuoteScanResult extends QuoteScanResultController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, navigation, handleSaveData } = this.props
        const { quoteDetailData, quoteColumn, quoteComparisonData, quoteRowsPerPage, quotePage } = this.state

        const newDataKey =quoteComparisonData &&( Array.isArray(quoteComparisonData) ? [{name:'',data:quoteComparisonData}] : Object.entries(quoteComparisonData).map(([newKey, value]) =>{
            return {name:newKey,data:value};
        }));
        
        const combinedData = newDataKey?.reduce<DataItemQuote[]>((accValue, current) => {
            return accValue.concat(current?.data as DataItemQuote[]);
        }, []);

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            
                <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />
                <Box style={{display:"flex", justifyContent:"center", alignItems:"center"}}>

                <Grid
                        container
                        style={{
                            color: 'black',
                            alignItems: "center"
                        }}
                    >
                        <Grid>
                            {<ViewReportDetailsBreadCrumb  data-testId="breadcrumb" classes={classes} project={this.state.projectNmae} report={this.state.reportName} getNavigationViewreport={this.getNavigationViewreport} goBack={this.goBack}/>}
                        </Grid>
                    </Grid>
                            <Button className={classes.editButton} data-testid="handleEditQuotegoBackTestid" onClick={this.handleEditQuote}>
                                <img src={editSvg} />
                                {configJSON.editQuote}
                            </Button>
                </Box>
                <Box className={classes.quoteScanBox}>
                    <Typography variant="h5" className={classes.viewquoteTitle}>{configJSON.viewquoteTitle}</Typography>
                    <Box className={classes.quoteDeatilBox}>
                        <Grid container spacing={2}>
                            {
                                quoteDetailData?.map((quotesItem, index: number) => {
                                    const dateVal = moment(quotesItem.date).format("MM-DD-YYYY")
                                    const expiryDateVal = moment(quotesItem.expiration_date).format("MM-DD-YYYY")
                                    return (
                                        <Grid item sm={12} key={index} style={{display:"flex"}}>
                                            <Grid item sm={4} >
                                                <Box>
                                                    <Typography variant="h6" className={classes.quotedetailsContent}>{configJSON.quotedetailsContent}</Typography>
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.quoteNumberContent}</Typography>
                                                    <Typography className={classes.quoteContentBox}>{quotesItem.quote_id}</Typography>
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.quoteDateContent}</Typography>
                                                    <Typography className={classes.quoteContentDateBox}>{this.renderQuoteStartDate(quotesItem.date, dateVal)} <CalendarTodayOutlinedIcon/></Typography>
                                                    
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.expiryquoteTitle}</Typography>
                                                    <Typography className={classes.quoteContentDateBox}>{this.renderQuoteexpiryDateVal(quotesItem.expiration_date,expiryDateVal)}<CalendarTodayOutlinedIcon/></Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Box>
                                                    <Typography variant="h6" className={classes.quotedetailsContent}>{configJSON.supplierdetailsContent}</Typography>
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.supplierNameContent}</Typography>
                                                    <Typography className={classes.quoteContentBox}>{quotesItem.project}</Typography>
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.supplierAddressContent}</Typography>
                                                    <Typography className={classes.quoteContentBox}>{quotesItem.supplier_address}</Typography>
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.salesPersonTitle}</Typography>
                                                    <Typography className={classes.quoteContentBox}>{quotesItem.supplier_salesperson}</Typography>
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.supplierContactContent}</Typography>
                                                    <Typography className={classes.quoteContentBox}>{quotesItem.supplier_contact}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Box>
                                                    <Typography variant="h6" className={classes.quotedetailsContent}>{configJSON.projectdetailsContent}</Typography>
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.siteNameContent}</Typography>
                                                    <Typography className={classes.quoteContentBox}>{quotesItem.site_name}</Typography>
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.siteAddressContent}</Typography>
                                                    <Typography className={classes.quoteContentBox}>{quotesItem.site_address}</Typography>
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.contactPersonTitle}</Typography>
                                                    <Typography className={classes.quoteContentBox}>{quotesItem.site_contactperson}</Typography>
                                                    <Typography className={classes.quoteDetailTitle}>{configJSON.siteContactContent}</Typography>
                                                    {
                                                       quotesItem.site_contact === null ? <Typography className={classes.quoteContentBox}>{"Unknown"}</Typography> : <Typography className={classes.quoteContentBox}>{quotesItem.site_contact}</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                    <Box className={classes.itemtableBox}>
                        <Typography variant="h6" className={classes.quotedetailsContent}>{configJSON.itemdetailsContent}</Typography>
                        {newDataKey?.map((value:any,columnId:number) =>{
                              const begin = ((quotePage[columnId] || 1) - 1) * quoteRowsPerPage;
                              const endData = begin + quoteRowsPerPage;
                            const paginatedData = value?.data.slice(begin, endData);
                            return <>
                           {value?.name && (
                                    <Box className={classes.viewQuoteSegmentCategoryMainBox}>
                                        <Typography className={classes.viewQuoteSegmentLabel}>
                                            Segment/Category
                                        </Typography>
                                        <TextField variant="outlined" style={{ width: "250px", textOverflow: "ellipsis" }}
                                             value={value?.name} InputProps={{ readOnly: true }} disabled  size="small" />
                                    </Box>
                            )}
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    {quoteColumn.map((column:any) => (
                                        (column.label !== 'Tax (%)' || combinedData?.some((item: DataItemQuote) => item?.tax !== null)) ? (
                                            <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            >
                                            {column.label}
                                        </TableCell>): null
                                    ))}
                                </TableHead>
                                <TableBody className={classes.tableBodyBox}>

                                    {paginatedData?.map((quoteData:DataItemQuote, index:number) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>

                                                <TableCell>{begin + index + 1}</TableCell>
                                                <TableCell>{quoteData?.description}</TableCell>
                                                <TableCell>{quoteData?.ord_quantity}</TableCell>
                                                <TableCell className={classes.unitContent}>{quoteData?.unit}</TableCell>
                                                <TableCell>$ {quoteData?.price}</TableCell>
                                                {combinedData?.some((item:DataItemQuote) => item?.tax !== null) &&  <TableCell>{quoteData?.tax || 0}%</TableCell>}
                                                <TableCell>$ {quoteData?.amount}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Pagination
                            count={Math.ceil(value?.data?.length / quoteRowsPerPage)}
                            variant="outlined"
                            shape="rounded"
                            onChange={(event, page) => this.handleChangePage(event, page, columnId)}
                            data-testid="paginationTestId"
                        />
                        <Box className={classes.subTotalBox}>
                            {quoteDetailData?.map((totalVal, index: number) => {
                                return (
                                    <Box style={{display:"flex"}} key={index}>
                                        <Typography >{ this.segmentTotalRetrun(value?.name)}</Typography>
                                        <Typography style={{width:'200px'}} className={classes.quoteContentBox}>$ {paginatedData[0]?.category_subtotal || totalVal.subtotal}</Typography>
                                    </Box>
                                )
                            })}

                        </Box>
                        </>
                        })}
                    </Box>
                    <Box className={classes.quoteTotalAmountBox}>
                        {
                            quoteDetailData?.map((allAmount: any) => {
                                return (
                                    <Box>
                                        <Box className={classes.subTotalContentBox}>
                                            <Typography className={classes.quoteDetailTitle}>{configJSON.subTotalContent}</Typography>
                                            <Typography>$ {allAmount.gross_subtotal || allAmount.subtotal}</Typography>                                        </Box>
                                        <Box className={classes.subTotalContentBox}>
                                            <Typography className={classes.quoteDetailTitle}>{configJSON.chargesContent}</Typography>
                                            <Typography>$ {allAmount.s_and_h_charges}</Typography>
                                        </Box>
                                        <Box className={classes.subTotalContentBox}>
                                            <Typography className={classes.quoteDetailTitle}>{configJSON.otherchargesContent}</Typography>
                                            <Typography>$ {allAmount.other_charges}</Typography>
                                        </Box>

                                        <Box className={classes.subTotalContentBox}>
                                            <Typography className={classes.quoteDetailTitle}>{configJSON.discountContent}</Typography>
                                            <Typography>$ {allAmount.discount}</Typography>
                                        </Box>
                                        <Box className={classes.taxContent}>
                                                <Typography className={classes.quoteDetailTitle}>{configJSON.taxContent}</Typography>
                                            <Box className={classes.taxInputBox}>
                                                <Typography>{allAmount.tax_rate_amount}%</Typography>
                                                <Typography>$ {allAmount.tax}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className={classes.subTotalContentBox}>
                                            <Typography className={classes.totalPrice}>{configJSON.totalContent}</Typography>
                                            <Typography>$ {allAmount.total_price}</Typography>
                                        </Box>
                                    </Box>
                                )
                            })
                        }

                    </Box>
                </Box>
                <Button className={classes.goBackutton} onClick={this.goBackhandler} data-testid="goBackTestid">
                    {configJSON.goBack}
                </Button>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    checkboxChecked: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "150px",
        fontWeight: 500,
        color: '#87888F',
        textDecoration: "underline",
} as React.CSSProperties,

    textUnderStyle: {
        fontWeight: 500,
        textDecoration: "underline",
        color: '#87888F'
    },

    scanTextStyle: {
        color: "orange",
        fontWeight: 500
    }
}
const UploadReportContainer = styled(Box)({
    cursor: "pointer",
    "&:hover": {
        color: "#1d6dcb",
        textDecoration: "underline"
    }
})

export const QuoteScanResultStyle = () => ({
    quoteScanBox: {
        background: '#fff',
        borderRadius: '10px',
        padding: '50px 25px 50px 50px',
        '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '8px'
        },
        '& .MuiTableContainer-root': {
            overflowX: 'unset',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        },
        '& .MuiTableCell-head': {
            paddingLeft: 0,
            fontWeight: 600
        }

    },
    viewquoteTitle: {
        color: '#000',
        fontWeight: 600
    },
    quoteDetailTitle:{
        color: '#0a0019',
        fontWeight: 500,
        margin: '0 0 5px',
    },
    quoteContentBox:{
        color: '#aeaab2',
        fontWeight: 500,
        padding: '20px',
        border: '1px solid #d5d5d5',
        borderRadius: '5px',
        marginBottom: '30px',
        marginRight: '25px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as 'nowrap'
    },
    quoteContentDateBox:{
        color: '#aeaab2',
        fontWeight: 500,
        padding: '20px',
        border: '1px solid #d5d5d5',
        borderRadius: '5px',
        marginBottom: '30px',
        marginRight: '25px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    quoteDeatilBox: {
        paddingBottom: '20px',
    },
    quotedetailsContent: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        marginTop: '50px',
        marginBottom: '20px'
    },
    tableBodyBox: {
        "& td": {
            border: '1px solid #e6e6e6 !important',
            borderRadius: '5px',
            color: '#aeaab2',
            fontWeight: 500,
            '&:first-child':{
                textAlign: 'center',
                width: '75px',
                padding: 0
            }
        }
    },
    subTotalBox: {
        display: 'flex',
        justifyContent: 'end',
        margin: '5px -3px 50px 0',
        '& p:first-child':{
            color: '#000',
            marginTop: '20px'
        },
        '& p:last-child': {
            marginLeft: '20px',
            width: 'auto',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '15px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
            marginBottom: '30px',
            marginRight: '-3px',
        }
    },
    quoteTotalAmountBox: {
        border: '2px solid #eee',
        borderRadius: '8px',
        padding: '20px',
        width: '32%',
        margin: '0 20px 0 auto'
    },
    subTotalContentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
        '& p:last-child': {
            width: '35%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '12px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
        }
    },
    totalPrice:{
        fontWeight: 600,
        color: '#0a0019',
        margin: '15px 0 5px',
    },
    taxContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    taxInputBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        '& p:last-child': {
            width: '38%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '12px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
        },
        '& p:first-child': {
            width: '22%',
            marginRight: '20px',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '12px',
            border: '1px solid #d5d5d5',
            borderRadius: '5px',
        },
    },
    itemtableBox: {
        marginRight: '29px',
        '& table': {
            position: 'relative'
        },
        '& table::after': {
            content: '""',
            border: '1px solid #bebebe',
            borderRadius: '10px',
            position: 'absolute',
            top: '56px',
            left: '-7px',
            width: '101%',
            height: '92%',
            zIndex: 9
        },
        '& .MuiPagination-root': {
            marginTop: '5%',
            background: '#fff',
            boxShadow: '0 0 7px #e3d3d3',
            padding: '15px 20px',
            display: 'inline-block',
            marginLeft: '-3px'
        }
    },
    editButton: {
        background: '#05052b',
        color: '#fff',
        padding: '15px',
        fontSize: '16px',
        textTransform: 'inherit' as 'inherit',
        width:"15%",
        margin: '0 0 30px auto',
        "&:hover": {
            background: '#05052b',
            color: '#fff',
        },
        '& img': {
            width: '17px',
            height: '17px',
            fill: '#05052b',
            filter: 'invert()',
            marginRight: '8px',
        }
    },
    goBackutton: {
        background: 'transparent',
        color: '#000',
        padding: '15px 60px',
        fontSize: '16px',
        textTransform: 'inherit' as 'inherit',
        marginTop: '30px',
        border: '1px solid #000',
        display: 'block',
        "&:hover": {
            background: 'transparent',
            color: '#000',
        },
    },
    unitContent:{
        textTransform: 'capitalize' as 'capitalize'
    },
    viewQuoteSegmentCategoryMainBox :{
        marginBottom:'30px',
        alignItems:"center",
        display:"flex",
        gap:'30px'
    },
    viewQuoteSegmentLabel:{
        fontSize:'14px',
        color:'#05052B',
        fontWeight:400
    },
})

export { QuoteScanResult }
export default withStyles(QuoteScanResultStyle)(QuoteScanResult)


// Customizable Area End
